<template>
    <div class="checkTask">
      <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>排班管理</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goback1">排班规则</el-breadcrumb-item>
          <el-breadcrumb-item>查看规则</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tableBox">
          <!-- blue_title -->
            <div class="blueTitle" style="margin-left:12px;">排班规则</div>
            <div class="baseInfo">
                <div class="bs-left">
                    <span class="bs-name">规则名称*</span>
                    <el-input v-model="ruleName" style="width:326px;height:44px"></el-input>
                </div>
                <div class="bs-right">
                    <span class="bs-name">适用范围*</span>
                    <el-radio-group v-model="workdayType" @change="setDocder">
                            <el-radio label="科室">适用于科室</el-radio>
                            <el-radio label="医生">适用于医生</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <!-- 选择医院与科室 -->
            <div class="baseInfo">
                <div class="bs-right">
                    <span class="bs-name">选择科室*</span>
                    <el-select style="width:326px" v-model="roomId" filterable clearable placeholder="请选择" @change="setHosk">
                        <el-option
                            v-for="(item,index) in optionsk"
                            :key="index"
                            :label="item.deptName"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <!-- 规则设置 -->
            <div class="blueTitle" style="margin-left:12px;margin-top: 20px;">规则设置</div>
            <div class="set-rules">
                <div class="top1">
                    <span class="bs-name" style="margin-bottom:14px">排班单位*：</span>
                    <div class="top1-gight">
                        <el-select v-model="uint" style="width:326px;display: block;" @change="selectRoom">
                            <el-option
                                v-for="item in uintList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-radio-group v-model="directionUint" style="width:326px !important;display: flex !important;justify-content: space-between !important;margin-top: 12px;" @change="setHolady">
                            <el-radio label="false">法定节假日</el-radio>
                            <el-radio label="true">无法定节假日</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                
                <div class="top2">
                    <div class="top2-left" >
                        <span class="bs-name" style="display:block">上班时间*</span>
                        <div class="select-time-box" v-if="uint!=20">
                             <span style="margin-right:12px">上午</span>
                            <el-time-picker style="width:302px" value-format="HH-mm-ss" format='HH:mm' is-range v-model="classTime.start" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" :default-value="defaultStartTime"></el-time-picker>
                        </div>
                    </div>
                    <div class="top2-left">
                        <div class="select-time-box" v-if="uint!=10">
                             <span style="margin-right:12px">下午</span>
                            <el-time-picker style="width:302px" value-format="HH-mm-ss" format='HH:mm' is-range v-model="classTime.end" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" :default-value="defaultEndTime"></el-time-picker>
                        </div>
                    </div>
                </div>
                <div class="top3">
                    <span class="top3-title">适用星期*</span>
                    <div class="week-box">
                        <div :class=" weekActive1 ? 'box-day' : 'box-day-no'" @click="setweeks1">周一</div>
                        <div :class=" weekActive2 ? 'box-day' : 'box-day-no'" @click="setweeks2">周二</div>
                        <div :class=" weekActive3 ? 'box-day' : 'box-day-no'" @click="setweeks3">周三</div>
                        <div :class=" weekActive4 ? 'box-day' : 'box-day-no'" @click="setweeks4">周四</div>
                        <div :class=" weekActive5 ? 'box-day' : 'box-day-no'" @click="setweeks5">周五</div>
                        <div :class=" weekActive6 ? 'box-day' : 'box-day-no'" @click="setweeks6">周六</div>
                        <div :class=" weekActive7 ? 'box-day' : 'box-day-no'" @click="setweeks7">周日</div>
                    </div>
                </div>
                <div class="top4" v-if="uint==40">
                    <div class="top4-left">
                        <span class="bs-name" style="display:block;margin-top:16px ">时间段时间*</span>
                        <div>
                            <el-input v-model="timeRange" type="number" style="width:240px" placeholder="请输入时间"></el-input>
                            <span style="margin-left:12px">分钟</span>
                            <div style="width:240px;font-size:10px;color:#999999;margin-top: 10px;">每个时间段的长度，单位分钟，默认30分钟 例如9:00-9:30，9:30-10:00</div>
                        </div>
                    </div>
                     <div class="top4-right">
                        <span class="bs-name" style="display:block;margin-top:16px ;">时间间隔*</span>
                        <div>
                            <el-input v-model="timeGap" type="number" style="width:200px" placeholder="请输入时间"></el-input>
                            <span style="margin-left:12px">分钟</span>
                            <div style="width:200px;font-size:10px;color:#999999;margin-top: 10px;">每两个时间段之间的长度，单位分钟，默认0 例如：当该值为10时，则 9:00-9:30 下一时间段为9:40-10:10</div>
                        </div>
                    </div>
                </div>
                <div class="top5">
                        <div class="top5-left" v-if="uint!=20">
                            <span class="bs-name" style="width:90px">上午各个时间段最大号源</span>
                            <div class="t5r">
                                <el-input v-model="amMaxNum" style="width:240px" placeholder="输入号源数量"></el-input>
                                <div class="t5r-tip">当前每个{{uintMap.get(uint)}}的号源数量为{{amMaxNum}}</div>
                            </div>
                        </div>
                        <div class="top5-right">
                            <span class="bs-name" style="display:block">预约限制*</span>
                            <el-radio-group v-model="allowMultiple" style="width:240px !important;display: flex !important;justify-content: space-between !important;">
                            <el-radio label="false">1人一个号/天</el-radio>
                            <el-radio label="true">1人可多个号/天</el-radio>
                        </el-radio-group>
                        </div>
                </div>
                <div class="top5">
                        <div class="top5-left" v-if="uint!=10">
                            <span class="bs-name" style="width:90px">下午各个时间段最大号源</span>
                            <div class="t5r">
                                <el-input v-model="pmMaxNum" style="width:240px" placeholder="输入号源数量"></el-input>
                                <div class="t5r-tip">当前每个{{uintMap.get(uint)}}的号源数量为{{pmMaxNum}}</div>
                            </div>
                        </div>
                </div>
                        
            </div>
            <!-- footer -->
            <div class="bottom-botton">
                <div class="bb-save" @click="save">更新</div>
                <div class="bb-cancel" @click="cancelLast">取消</div>
            </div>
            
      </div>
    </div>
</template>
<script>
import {updateRules,getHospitalList,getRoomList,viewRules} from '@/api/order.js'
export default {
   
    data(){
        const uintMap = new Map([[10,'按上午排班'],[20,'按下午排班'],[30,'按上午+下午排班'],[40,'按分钟排班']])
        return{
            ruleID:null,//规则id
            uintMap,
            weekActive1:false,
            weekActive2:false,
            weekActive3:false,
            weekActive4:false,
            weekActive5:false,
            weekActive6:false,
            weekActive7:false,
           
            // 需要提交的
            hospitalId:null,//医院id
            roomId:null,//科室id
            ruleName:null,//规则名称  
            workdayType:null,//适用范围 string （科室，医生)
            directionUint:null,//排班单位是否有节假日  true false
            uint:null,//排班单位workdayTimeType
            classTime:{start:null,end:null},//上班时间上下午
            timeGap:null,//间隔时间  每个时间段的长度，单位分钟，默认30分钟 例如9:00-9:30，9:30-10:00
            timeRange:null,//时间段时间
            amMaxNum:null,//上午最大号源 ?
            allowMultiple:null,//预约限制 true  false
            pmMaxNum:null,//下午最大号源  ?
            //
            uintList:[{label:'按上午排班',value:10},{label:'按下午排班',value:20},{label:'按上午+下午排班',value:30},{label:'按分钟排班',value:40}],
            //上午时间
            optionsp:null,//医院列表
            optionsk:null,//科室列表
            defaultStartTime: [new Date(2016, 9, 10, 8, 0, 0), new Date(2016, 9, 10, 12, 0, 0)],
            defaultEndTime: [new Date(2016, 9, 10, 14, 0, 0), new Date(2016, 9, 10, 18, 0, 0)]
        }
    },
    created(){
        if(this.$route.query.id){
          this.ruleID = this.$route.query.id;
          this.viewRule( this.ruleID);
        }
    },
    mounted(){
        this.getHoList();
    },

    methods:{
        //面包屑
        goback1(){
            this.$router.go(-1);
        },
        //适用范围
        setDocder(e){
            //console.log("适用范围",this.workdayType)
        },
        //选择排班单位
        selectRoom(e){
            //console.log("选中值",this.uintMap.get(e));
           //this.uintMap.get(e)
        },
        //法定节假日
        setHolady(e){
           // console.log("是否忽略节假日",e)
        },
        //seetweeks
        setweeks1(){
            if(this.weekActive1==true){
                this.weekActive1 = false;
            }else{
                this.weekActive1 = true;
            }
        },
         setweeks2(){
            if(this.weekActive2==true){
                this.weekActive2 = false;
            }else{
                this.weekActive2 = true;
            }
        },
         setweeks3(){
            if(this.weekActive3==true){
                this.weekActive3 = false;
            }else{
                this.weekActive3 = true;
            }
        },
         setweeks4(){
            if(this.weekActive4==true){
                this.weekActive4 = false;
            }else{
                this.weekActive4 = true;
            }
        },
         setweeks5(){
            if(this.weekActive5==true){
                this.weekActive5 = false;
            }else{
                this.weekActive5 = true;
            }
        },
        setweeks6(){
            if(this.weekActive6==true){
                this.weekActive6 = false;
            }else{
                this.weekActive6 = true;
            }
        },
         setweeks7(){
            if(this.weekActive7==true){
                this.weekActive7 = false;
            }else{
                this.weekActive7 = true;
            }
        },

        //submit
        save(){
            console.log(this.timeRange)
            //1 判断其它非空字段
            var mustArr=null;
            if(this.uint==10){
                mustArr= [this.roomId,this.ruleName,this.workdayType,this.directionUint,this.uint,this.amMaxNum,this.allowMultiple,this.classTime.start];
            }
            if((this.uint!=10)&&(this.uint!=40)){
                mustArr= [this.roomId,this.ruleName,this.workdayType,this.directionUint,this.uint,this.pmMaxNum,this.allowMultiple,this.classTime.end];
            }
            if(this.uint == 40){
                mustArr= [this.roomId,this.ruleName,this.workdayType,this.directionUint,this.uint,this.timeGap,this.timeRange,this.pmMaxNum,this.allowMultiple];
            }
             
            var next = mustArr.every((item,index)=>{
                return (item !=null)
            });

            //2 判断周
            var temp = [];
            var newWeek = [this.weekActive1,this.weekActive2,this.weekActive3,this.weekActive4,this.weekActive5,this.weekActive6,this.weekActive7];
            newWeek.forEach((item,index)=>{
                if(item){
                    temp.push(index+1)
                }
            })

            //判断上班时间
            var classtime =false;
            if((this.classTime.start==null)&&(this.classTime.end==null)){
                classtime =false
            }else{
                classtime =true
            }
            var lastWeek = temp.toString();
            //判断上班时间 
            //
            var alastTime1 = null;
            var alastTime2 = null;
            var plastTime1 = null;
            var plastTime2 = null;
            var amsTimeh=null;
            var amsTimem=null;
            var amsTimes=null;

            var ameTimeh=null;
            var ameTimem=null;
            var ameTimes=null;

            if(this.classTime.start==null){
               alastTime1= null;
               alastTime2= null;
            }else{
                amsTimeh=parseInt(this.classTime.start[0].substring(0,2));
                amsTimem=parseInt(this.classTime.start[0].substring(3,5));
                amsTimes=parseInt('00');
                // amsTimes=parseInt(this.classTime.start[0].substring(6,8));
                ameTimeh=parseInt(this.classTime.start[1].substring(0,2));
                ameTimem=parseInt(this.classTime.start[1].substring(3,5));
                ameTimes=parseInt('00');
                // ameTimes=parseInt(this.classTime.start[1].substring(6,8));
                alastTime1 = [amsTimeh,amsTimem,amsTimes];
                alastTime2 = [ameTimeh,ameTimem,ameTimes];

            }

           
            var pmsTimeh=null;
            var pmsTimem=null;
            var pmsTimes=null;

            var pmeTimeh=null;
            var pmeTimem=null;
            var pmeTimes=null;

            if(this.classTime.end==null){
                plastTime1 = null;
                plastTime2 = null;

            }else{
                pmsTimeh=parseInt(this.classTime.end[0].substring(0,2));
                pmsTimem=parseInt(this.classTime.end[0].substring(3,5));
                pmsTimes=parseInt('00');
                // pmsTimes=parseInt(this.classTime.end[0].substring(6,8));
                pmeTimeh=parseInt(this.classTime.end[1].substring(0,2));
                pmeTimem=parseInt(this.classTime.end[1].substring(3,5));
                pmeTimes=parseInt('00');
                // pmeTimes=parseInt(this.classTime.end[1].substring(6,8));
                plastTime1 = [pmsTimeh,pmsTimem,pmsTimes];
                plastTime2 = [pmeTimeh,pmeTimem,pmeTimes];

            }

            
            //last 
            if(((next)&&(classtime))&&(lastWeek!='')){
                //console.log("可以提交");
                updateRules({
                    id:this.ruleID,
                    allowMultiple:this.allowMultiple,
                    amMaxNum:this.amMaxNum,
                    ignoreHoliday:this.directionUint,
                    pmMaxNum:this.pmMaxNum,
                    ruleName:this.ruleName,
                    timeGap:this.timeGap,
                    timeRange:this.timeRange,
                    weekDay:lastWeek,
                    workdayTimeType:this.uint,
                    workdayType:this.workdayType,
                    amStartTime:alastTime1,
                    amTimeEnd:alastTime2,
                    wid:this.roomId,
                    pmStartTime:plastTime1,
                    pmTimeEnd:plastTime2,
                }).then(res=>{
                    if(res.data.status==200){
                        this.$message({
                            message: '更新成功！',
                            type: 'success',
                            duration:1800
                        });
                       setTimeout(()=>{
                        this.$router.back();
                       },2000)
                    }else{
                        this.$message.error(res.data.message);
                    }
                })

            }else{
                this.$message.error("必填项不能为空！");
            }
        },
        //取消
        cancelLast(){
            this.hospitalId=null;
            this.roomId=null;
            this.ruleName=null;  
            this.workdayType=null;
            this.directionUint=null;
            this.uint=null;
            this.classTime.start=null;
            this.classTime.end=null;
            this.timeGap=null;
            this.timeRange=null;
            this.amMaxNum=null;
            this.allowMultiple=null;
            this.pmMaxNum=null
        },

        //查询医院列表
        getHoList(){
            getHospitalList({
                //当前该账户下以默认绑定有的
            }).then(res=>{
                if(res.data.status==200){
                    this.hospitalId = res.data.data.id;
                     //开始查询科室列表
                    getRoomList({
                        orgId:this.hospitalId
                    }).then(res=>{
                        if(res.data.status==200){
                            this.optionsk = res.data.data.records;
                        }else{
                            this.$message.error(res.data.message);
                        }
                    })
                }else{
                    this.$message.error(res.data.message);
                }
            })
        },
        //选择科室id 
        setHosk(e){
            this.roomId = e;
        },

        //查看回显信息
        viewRule(){
            viewRules({
                id:this.ruleID
            }).then(res=>{
                    if(res.data.status==200){
                       // console.log("rulue",res.data.data);
                        var revieData = res.data.data
                        //信息回显
                        this.ruleName = revieData.ruleName;
                        this.workdayType = revieData.workdayType;
                        this.roomId = revieData.wid;
                        this.uint = revieData.workdayTimeType;
                        this.directionUint = revieData.ignoreHoliday.toString();
                        this.timeGap = revieData.timeGap;
                        this.timeRange = revieData.timeRange;
                        if((revieData.amStartTime!=null) && (revieData.amTimeEnd!=null)){
                            this.classTime.start = [revieData.amStartTime.replace(/:/g,'-'),revieData.amTimeEnd.replace(/:/g,'-')];
                        }
                        if((revieData.pmStartTime!=null) && (revieData.pmTimeEnd!=null)){
                            this.classTime.end = [revieData.pmStartTime.replace(/:/g,'-'),revieData.pmTimeEnd.replace(/:/g,'-')];
                        }
                        //周
                        var temp_week = revieData.weekDay;
                        for (let char of temp_week) {
                            switch (char) {
                                case "1":
                                this.weekActive1 = true;
                                    break;
                                case "2":
                                    this.weekActive2 = true;
                                    break;
                                case "3":
                                    this.weekActive3 = true;
                                    break;
                                case "4":
                                    this.weekActive4 = true;
                                    break;
                                case "5":
                                    this.weekActive5 = true;
                                    break;
                                case "6":
                                    this.weekActive6 = true;
                                    break;
                                case "7":
                                    this.weekActive7 = true;
                                    break;
                                default:
                                  //
                                    break;
                                }
                             }
                            this.amMaxNum = revieData.amMaxNum;
                            this.allowMultiple = revieData.allowMultiple.toString();
                            this.pmMaxNum = revieData.pmMaxNum;
                    }else{
                        this.$message.error(res.data.message);
                    }
            })
        },

    }
}
</script>
<style scoped lang="stylus">
//change elemen-ui style
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
/deep/.el-input__inner {
    border: 1px solid #00cccc !important;
}
/deep/.el-radio__inner{
    border: 1px solid #00cccc !important;
}
/deep/.el-radio__input.is-checked .el-radio__inner{
    background: #00cccc !important;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
    color: #00cccc !important;
}

//component style
.blueTitle
  font-size: 18px 
  color: #353535
  display: flex
  align-items: center 
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:1px
    background-color: #00CCCC

.bs-name
    margin-right: 20px
    display: block 
    width: 90px


//normal style
.checkTask
    overflow hidden
    margin-top: 36px
    .tableBox
        overflow hidden
        margin-top: 16px
        padding: 20px
        background-color: #FFFFFF
        .tableTop
            display: flex
            margin-top: 20px
            margin-bottom: 30px
            align-items: center
            justify-content: space-between
            .table_l
                display: flex
                .add
                    width: 140px 
                    height: 32px 
                    background-color: #00cccc 
                    text-align: center
                    border-radius:2px 
                    font-size: 14px 
                    color: #fff
                    line-height: 32px 
                    cursor pointer
            .table_r
                display: flex
                align-items: center
                .search
                    height: 32px
                    margin-left: 16px
                    display: flex
                    .secbox
                        width: 88px
                        height: 32px  
                        background-color: #00cccc
                        text-align: center
                        line-height: 32px
                        font-size: 14px
                        color: #fff

.baseInfo
    width: 100%
    height: 104px 
    margin-top: 20px
    box-sizing: border-box
    padding: 30px 184px
    border: 1px solid #CEF8F8
    display: flex
    justify-content: space-between
    align-items: center
    .bs-left
        display: flex
        align-items: center
    .bs-right
        display: flex
        align-items: center

.set-rules
    width: 100%
    margin-top: 20px 
    border: 1px solid #CEF8F8
    box-sizing: border-box
    padding: 30px 184px
    .top1
        overflow hidden
        display: flex
        align-items: center
    .top2
        display: flex
        margin-top: 100px
        justify-content: space-between
        .top2-left
            overflow hidden
            display: flex
            align-items: center
    .top3
        display: flex
        margin-top: 100px
        .top3-title
            margin-right: 40px 
            width 80px
        .week-box
            display: flex
            width: 100%
            margin-left: 20px
            justify-content: space-between
            .box-day
                width: 100px
                height: 100px
                border-radius: 4px 
                text-align: center
                line-height: 100px 
                cursor pointer
                color: #fff
                font-size: 20px
                background-color: #00cccc
            .box-day-no
                width: 100px
                height: 100px
                border-radius: 4px 
                cursor pointer
                text-align: center
                line-height: 100px 
                color: #666
                font-size: 20px
                background-color: #F3F5F6
    .top4 
        margin-top: 100px 
        display: flex
        align-items: center
        justify-content: space-between
        .top4-left
            display: flex
        .top4-right
            display: flex
            
    .top5
        margin-top: 100px 
        display: flex
        align-items: center
        justify-content: space-between
        .top5-left
            display: flex
            align-items: center
            .t5r
                overflow hidden
                text-align: left
                width:326px
                .t5r-tip
                    font-size:14px 
                    color: #999999 
                    margin-top: 8px
        .top5-right
            display: flex
            align-items: center
.bottom-botton
    margin-top: 60px  
    display: flex
    transform: translate(70%,0)
    .bb-save
        width: 160px 
        height: 44px 
        background-color: #00CCCC
        font-size: 18px 
        color: #fff 
        text-align: center
        line-height: 44px 
        border-radius: 4px 
        margin-right: 30px 
        cursor pointer
    .bb-cancel
        width: 160px 
        height: 44px
        font-size: 18px 
        color: #00cccc
        cursor pointer
        text-align: center
        line-height: 44px 
        border-radius: 4px 
        border: 1px solid #00cccc



               

            
</style>